import React from 'react'
import Helmet from 'react-helmet'
import { withPrefix } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/Layout'
import Container from '../components/Container'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'
import ZoneIndustries from '../components/Industries'

import {
  above,
  secondary,
  DownloadButton,
  Zone,
  BoxWrapper,
  Box,
  BoxInner,
  CardTitle,
  Button,
  LinkWrapper,
  InlineBox,
  SidebarLayout,
  PageHeader,
  LargeP,
  BenefitsItem,
  BenefitsSidebarList,
} from '../elements'

import payment from '../images/services/payment-services.svg'
import tech from '../images/services/technology.svg'
import businessservices from '../images/services/business-services.svg'
import maintenance from '../images/services/maintenance.svg'
import hero from '../images/slideshow/ddtsi-hagerman-id.png'
import carrierhero from '../images/slideshow/carrier-over-the-road.png'

const CarriersPage = ({ data }) => {
  const postNode = {
    title: `Carriers - ${config.siteTitle}`,
  }

  return (
    <Layout>
      <Helmet>
        <title>{`Carriers - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="carriers" customTitle />

      <Zone
        modifiers={['darkOverlay', 'center', 'short']}
        hero
        image={carrierhero}
      >
        <div className="zone-content">
          <h2>Guaranteed Loads, Great Rates</h2>
          <h3>
            We have a longstanding, exceptional reputation for unsurpassed
            carrier service, commitment, and satisfaction. We go the extra mile.
          </h3>
          <Button to="/careers">Become a Carrier</Button>
        </div>
      </Zone>

      <Container>
        {/* <PageTitle>Carriers</PageTitle> */}

        <SidebarLayout
          modifiers={['translucent']}
          style={{
            margin: '1rem 0 5rem 0',
          }}
        >
          <div>
            <PageHeader>A Fleet of One or One Hundred</PageHeader>
            <LargeP>
              As an asset-based logistics company we understand carrier needs.
              Our seasoned team of logistics professionals give personalized
              attention to specific carrier needs.
            </LargeP>
            <p>
              Through active communication, we work with our carriers to
              understand their freight movement and business needs, and we are
              able to plan for the freight they need when they need it. Let us
              help you keep your equipment loaded and on the road. We offer
              unbeatable freight options, fast payment, automated
              communications, and programs to simplify your life.
            </p>
            <InlineBox style={{ marginTop: '2rem', marginBottom: '0' }}>
              <h4>Become a Carrier</h4>
              <p>
                Becoming a Carrier for D&D is easy. Download and fill out our
                Carrier Packet and provide the necessary operating authority,
                proof of insurance, and Carrier Profile so we can understand
                your operational needs. We look forward to partnering with you
                for all of your transportation needs.
              </p>
              <Button modifiers={['outline']} to="/contact">
                Get Started, Fill Out Our Contact Form&nbsp;
                <i className="material-icons">chevron_right</i>
              </Button>
            </InlineBox>
          </div>
          <div className="benefits-sidebar">
            <h4>Our Benefits </h4>
            <BenefitsSidebarList>
              <BenefitsItem>
                <i className="material-icons">local_atm</i> Free Quick Pay
              </BenefitsItem>
              <BenefitsItem>
                <i className="material-icons">local_gas_station</i> Fuel Card
                Discount
              </BenefitsItem>
              <BenefitsItem>
                <i className="material-icons">settings</i> Maintenance, Repair,
                & Tire Discounts
              </BenefitsItem>
              <BenefitsItem>
                <i className="material-icons">grain</i> Wash & Wash-out
                Discounts
              </BenefitsItem>
              <BenefitsItem>
                <i className="material-icons">gps_fixed</i> Drop Trailer Program
              </BenefitsItem>
              <BenefitsItem>
                <i className="material-icons">flare</i> Clean, No-Touch Freight
              </BenefitsItem>
              <BenefitsItem>
                <i className="material-icons">map</i> Route Optimization
              </BenefitsItem>
              <BenefitsItem>
                <i className="material-icons">beenhere</i> CSA & Smartway
                Compliance
              </BenefitsItem>
              <BenefitsItem>
                <i className="material-icons">ring_volume</i> On Call 24/7
                Service
              </BenefitsItem>
            </BenefitsSidebarList>
          </div>
        </SidebarLayout>

        <BoxWrapper>
          <ServiceBox>
            <FlexWrapper to="/assured-loads" asModal>
              <CardImg>
                <img src={tech} alt="Assured Loads & Great Rates" />
              </CardImg>
              <HangingBoxInner>
                <CardTitle>Assured Loads & Great Rates</CardTitle>
                <p>
                  We understand that your job is more than just the next
                  delivery. Accommodating for freight in the lanes that meet
                  your personal and professional need is a priority.
                </p>
                <Button modifiers={['outline']} to="/assured-loads" asModal>
                  Learn More&nbsp;
                  <i className="material-icons">add_circle_outline</i>
                </Button>
              </HangingBoxInner>
            </FlexWrapper>
          </ServiceBox>
          <ServiceBox>
            <FlexWrapper to="/payment-services" asModal>
              <CardImg>
                <img src={payment} alt="D&D Payment Services" />
              </CardImg>
              <HangingBoxInner>
                <CardTitle>Payment Services</CardTitle>
                <p>
                  At D&D, we are happy to pay for a job well done and pride
                  ourselves utilizing the latest technology for quick payment
                  methods. With no fee direct deposits and fast fuel
                  reimbursements, we'll help keep your operation run
                  efficiently.
                </p>
                <Button modifiers={['outline']} to="/payment-services" asModal>
                  Learn More&nbsp;
                  <i className="material-icons">add_circle_outline</i>
                </Button>
              </HangingBoxInner>
            </FlexWrapper>
          </ServiceBox>

          <ServiceBox>
            <FlexWrapper to="/business-services" asModal>
              <CardImg>
                <img src={businessservices} alt="D&D Business Services" />
              </CardImg>
              <HangingBoxInner>
                <CardTitle>Business Support Services</CardTitle>
                <p>
                  With over three decades of experience, D&D understands the
                  delicate balance of running a business and getting the job
                  done efficiently. Rely on our office and logistical staff to
                  manage the paperwork while you drive your bottom line.
                </p>
                <Button modifiers={['outline']} to="/business-services" asModal>
                  Learn More&nbsp;
                  <i className="material-icons">add_circle_outline</i>
                </Button>
              </HangingBoxInner>
            </FlexWrapper>
          </ServiceBox>
          <ServiceBox>
            <FlexWrapper to="/maintenance" asModal>
              <CardImg>
                <img src={maintenance} alt="D&D Maintenance Services" />
              </CardImg>
              <HangingBoxInner>
                <CardTitle>Maintenance Discounts</CardTitle>
                <p>
                  D&D has a full service maintenance facility to maintain the
                  best operating conditions for our carrier partners. Our
                  service facility is fully equipped to repair all aspects of
                  both tractor and trailer with experienced and certified
                  technicians.
                </p>
                <Button modifiers={['outline']} to="/maintenance" asModal>
                  Learn More&nbsp;
                  <i className="material-icons">add_circle_outline</i>
                </Button>
              </HangingBoxInner>
            </FlexWrapper>
          </ServiceBox>
        </BoxWrapper>
      </Container>

      <Zone
        modifiers={['darkOverlay', 'center', 'short']}
        left
        hero
        image={hero}
      >
        <div className="zone-content">
          <Testimonial>
            <p>
              16 years and hundreds upon hundreds of trucks we are unloading our
              very first D&D truck full of nursery stock. And the very first
              time I have ever had a driver bring drinks for my crew. Kudos on a
              great business model well done!”
            </p>
            <cite>Ryan Muchow, Windsor’s Nursery</cite>
          </Testimonial>
        </div>
      </Zone>

      <ZoneIndustries />
    </Layout>
  )
}

export default CarriersPage

const ServiceBox = styled(Box)`
  flex: 0 0 100%;
  margin: 0 0 2rem 0;
  ${above.sm`
    flex: 0 0 49%;
  `};
`
const CardImg = styled.div`
  /* flex-flow: row wrap; */
  min-width: 208px;
  background: ${secondary};
`
const HangingBoxInner = styled(BoxInner)`
  margin: 0;
  height: 100%;
  ${above.lg`
    margin: 1rem 0 1rem -2rem;
    height: auto;
  `}
`
const FlexWrapper = styled(LinkWrapper)`
  flex-flow: column;
  ${above.lg`
    flex-flow: row;
  `}
`
const Testimonial = styled.blockquote`
  p {
    font-size: 1.35rem;
    line-height: 1.85rem;
  }
  cite,
  cite a {
    font-size: 1.15rem;
  }
`
const InlineLinkWrapper = styled(LinkWrapper)`
  height: auto;
  margin: 0;
`
